import { Button, Divider, Table, TableBody, TableRow } from "@mui/material";
import { compact } from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import { useLocation } from "react-router";
import { SEARCH_FIELD_MAX_WIDTH } from "../../../config";
import { useActivateRoleDialog } from "../../../hooks/useActivateRoleDialog";
import { useAddRoleFlow } from "../../../hooks/useAddRoleFlow";
import { useContextMenu } from "../../../hooks/useContextMenu";
import { useDeactivateRoleDialog } from "../../../hooks/useDeactivateRoleDialog";
import { useDeleteRoleDialog } from "../../../hooks/useDeleteRoleDialog";
import { useDuplicateRoleFlow } from "../../../hooks/useDuplicateRoleFlow";
import { useManagePermissionsFlow } from "../../../hooks/useManagePermissionsFlow";
import { useMultiSelectionFilter } from "../../../hooks/useMultiSelectionFilter";
import { usePagination } from "../../../hooks/usePagination";
import { useRoleDetailsFlow } from "../../../hooks/useRoleDetailsFlow";
import { useRoleGroups } from "../../../hooks/useRoleGroups";
import { useRoleStatusFilter } from "../../../hooks/useRoleStatusFilter";
import { useRoles } from "../../../hooks/useRoles";
import { t } from "../../../i18n/util";
import { Role } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute, withParams, withQuery } from "../../app/router/history";
import { EmptyState } from "../../ui/EmptyState";
import { CmsTableCell, CmsTableContainer, SettingSubSiteContainer, TableLabel } from "../../ui/Primitives";
import { roleStatusToProps } from "../../ui/RoleStatus";
import { SearchField } from "../../ui/SearchField";
import { TableContextButton } from "../../ui/TableContextButton";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { TableStatusCell } from "../../ui/TableStatusCell";
import { FilterComponents, FilterResetComponents } from "../../ui/filters/Filters";
import { AccountTypes } from "../../util/AccountTypes";
import { SettingsRoutes } from "../router/SettingsRoutes";
import { Routes } from "../../app/router/Routes";

export const getFormattedAccountCustomerTypes = (role: Role) =>
    role?.isDefaultForAccountTypeCustomerTypeCombinations
        .map((combination) => `${t(AccountTypes[combination.accountType])} - ${combination.customerType}`)
        .join(" | ");
export const SettingsRolesSite = observer(() => {
    const [contextRole, setContextRole] = useState<Role>();
    const pagination = usePagination();
    const location = useLocation();

    const { roleGroupOptions } = useRoleGroups();

    const roleGroupsFilter = useMultiSelectionFilter({
        name: "roleGroup",
        label: t("filter.label.roleGroup"),
        options: roleGroupOptions,
    });

    const statusFilter = useRoleStatusFilter();

    const filters = [roleGroupsFilter, statusFilter];

    const { rolesResponse, reloadRoles, isLoading, isInitialized, isEmpty } = useRoles({
        offset: pagination.offset,
        limit: pagination.pageSize,
        orderBy: pagination.orderBy,
        orderDir: pagination.orderDir,
        search: pagination.search,
        status: statusFilter.values,
        roleGroups: roleGroupsFilter.values.map((value) => (value === "none" ? "" : value)),
    });

    const roleDetails = useRoleDetailsFlow({
        reloadRoles,
    });

    const addRoleFlow = useAddRoleFlow({ reloadRoles });
    const duplicateRoleFlow = useDuplicateRoleFlow({ sourceRole: contextRole, reloadRoles });
    const managePermissionsFlow = useManagePermissionsFlow({ role: contextRole, reloadRoles });
    const deleteDialog = useDeleteRoleDialog(contextRole?.id, reloadRoles);
    const deactivateDialog = useDeactivateRoleDialog({ role: contextRole, reload: reloadRoles });
    const activateDialog = useActivateRoleDialog(contextRole?.id, reloadRoles);

    const handleClickAddRole = () => {
        addRoleFlow.open();
    };

    const handleClickEditRole = (role: any) => {
        roleDetails.open(role);
    };

    const handleClickActivateRole = (role: any) => {
        setContextRole(role);
        activateDialog.open();
    };

    const handleClickDeactivateRole = (role: any) => {
        setContextRole(role);
        deactivateDialog.open();
    };

    const handleClickDuplicateRole = (role: any) => {
        setContextRole(role);
        duplicateRoleFlow.open();
    };

    const handleClickEditPermissions = (role: any) => {
        setContextRole(role);
        managePermissionsFlow.open();
    };

    const handleClickDeleteRole = (role: any) => {
        setContextRole(role);
        if (role.userCount) {
            deactivateDialog.open({ isDelete: true });
        } else {
            deleteDialog.open();
        }
    };

    const handleClickShowUsersWithRole = (role: Role) => {
        pushRoute(withQuery(Routes.USERS, { roles: [role.id] }));
    };

    const headerFields: TableHeaderConfig[] = [
        { column: "name", label: "table.label.role", style: { width: "20%" } },
        { column: "description", label: "table.label.description" },
        {
            column: "defaultForAccountCustomerType",
            label: "table.label.defaultForAccountCustomerType",
            style: { width: 140 },
        },
        { column: "roleGroup", label: "table.label.roleGroup", style: { width: 0 } },
        { column: "userCount", label: "table.label.userCount", style: { width: 0 } },
        { column: "permissionCount", label: "table.label.permissions", style: { width: 0 } },
        { column: "status", label: "table.label.status", style: { width: 140 } },
        { column: "contextMenu", style: { width: 0 } },
    ];

    const contextMenu = useContextMenu<Role>((role) => {
        if (role) {
            return compact([
                role.status === "active"
                    ? {
                          title: t("manageRoleForm.edit.title"),
                          onClick: () => {
                              handleClickEditRole(role);
                          },
                      }
                    : undefined,
                role.status === "active" || role.status === "system"
                    ? {
                          title: t("button.duplicate"),
                          onClick: () => {
                              handleClickDuplicateRole(role);
                          },
                      }
                    : undefined,
                role.status === "active" || (role.status === "system" && role.permissionsEditable)
                    ? {
                          title: t("button.editPermissions"),
                          onClick: () => {
                              handleClickEditPermissions(role);
                          },
                      }
                    : undefined,
                role.status === "deactivated"
                    ? {
                          title: t("button.activate"),
                          onClick: () => {
                              handleClickActivateRole(role);
                          },
                      }
                    : undefined,
                role.status === "active"
                    ? {
                          title: t("button.deactivate"),
                          onClick: () => {
                              handleClickDeactivateRole(role);
                          },
                      }
                    : undefined,
                role.status === "active" || (role.status === "deactivated" && role.userCount === 0)
                    ? {
                          title: t("button.delete"),
                          onClick: () => {
                              handleClickDeleteRole(role);
                          },
                      }
                    : undefined,
                role.userCount && role.userCount > 0
                    ? {
                          title: t("button.show_users_with_role"),
                          onClick: () => {
                              handleClickShowUsersWithRole(role);
                          },
                      }
                    : undefined,
            ]);
        }

        return [];
    });

    const openDetailsSite = (roleId: string) => {
        generalStore.lastLocation = `${location.pathname}${location.search}`;
        pushRoute(withParams(SettingsRoutes.ROLE_DETAILS, { roleId }));
    };

    const tableBody = (
        <TableBody>
            {rolesResponse?.roles?.map((role) => {
                return (
                    <TableRow key={role.id} onClick={() => openDetailsSite(role.id)} style={{ cursor: "pointer" }}>
                        {headerFields.map(({ column }) => {
                            let label = (role as any)[column];

                            if (column === "defaultForAccountCustomerType") {
                                label = getFormattedAccountCustomerTypes(role);
                            } else if (column === "roleGroup") {
                                label = role.roleGroup?.name;
                            } else if (column === "status") {
                                return <TableStatusCell key={column} {...roleStatusToProps(role.status)} />;
                            } else if (column === "contextMenu") {
                                return (
                                    <TableContextButton
                                        key={column}
                                        onClick={(event) => {
                                            contextMenu.open(event, role);
                                            event.stopPropagation();
                                        }}
                                    />
                                );
                            }

                            return (
                                <CmsTableCell
                                    key={column}
                                    style={{
                                        maxWidth: column !== "name" && column !== "accountType" ? 100 : undefined,
                                    }}
                                >
                                    <TableLabel style={{ maxWidth: "100%" }}>{label}</TableLabel>
                                </CmsTableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );

    return (
        <SettingSubSiteContainer>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 8 }}>
                <FilterComponents filters={filters} />
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <div style={{ maxWidth: SEARCH_FIELD_MAX_WIDTH }}>
                        <SearchField
                            placeholder={t("screen.settings.roles.search.placeholder")}
                            onChange={pagination.onChangeSearch}
                            value={pagination.search}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <Button onClick={handleClickAddRole} variant="contained" style={{ flexShrink: 0 }}>
                        {t("screen.settings.roles.button.addRole.text")}
                    </Button>
                </div>
            </div>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <FilterResetComponents filters={filters} />
            {isInitialized && !isLoading && isEmpty && (
                <EmptyState
                    iconName="sadFace"
                    title={t(
                        pagination.search
                            ? "screen.settings.roles.noResult.title"
                            : "screen.settings.roles.emptyScreen.title",
                    )}
                    description={t("screen.settings.roles.emptyScreen.description")}
                />
            )}
            {pagination.component(rolesResponse?.total ?? 0)}
            {isInitialized && !isEmpty && (
                <CmsTableContainer>
                    <Table>
                        <TableHeader
                            headerFields={headerFields}
                            orderBy={pagination.orderBy}
                            orderDir={pagination.orderDir}
                            onChangeOrder={pagination.onChangeOrder}
                            allowSort
                        />
                        {tableBody}
                    </Table>
                </CmsTableContainer>
            )}
            {contextMenu.component}
            {roleDetails.component}
            {addRoleFlow.component}
            {duplicateRoleFlow.component}
            {managePermissionsFlow.component}
            {deleteDialog.component}
            {deactivateDialog.component}
            {activateDialog.component}
        </SettingSubSiteContainer>
    );
});
