import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { Stepper } from "../../../types";
import { DialogHeader } from "../../ui/DialogHeader";
import { DialogStepper } from "../../ui/DialogStepper";
import { RoleDetailsFields } from "./RoleDetailsFields";
import { useState } from "react";
import { NewGroupParams } from "../../../hooks/useRoleDetailsForm";

export type RoleDetailsFormData = {
    name: string;
    description?: string;
    roleGroupId?: string;
};

export const RoleDetailsForm = ({
    onClose,
    onSubmit,
    onNewGroup,
    stepper,
    validationSchema,
    initialValues,
    newRole,
}: {
    onClose: () => void;
    onNewGroup?: (params: NewGroupParams) => void;
    onSubmit: (model: RoleDetailsFormData) => void;
    stepper?: Stepper;
    validationSchema: Yup.SchemaOf<RoleDetailsFormData>;
    initialValues: RoleDetailsFormData;
    newRole?: boolean;
}) => {
    const [groupName, setGroupName] = useState<string>();

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ values }) => (
                <Form
                    style={{
                        width: "100%",
                        height: "inherit",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                    noValidate
                >
                    <div>
                        <DialogHeader
                            title={newRole ? t("manageRoleForm.add.title") : t("manageRoleForm.edit.title")}
                            onClose={onClose}
                        />
                        {stepper && <DialogStepper stepper={stepper} />}
                        <div style={{ marginTop: 40 }}>
                            <RoleDetailsFields
                                onNewGroup={() => {
                                    onNewGroup?.({ formData: values, initialGroupName: groupName });
                                }}
                                onChangeGroupName={(value) => setGroupName(value)}
                                isNewRole={newRole}
                            />
                        </div>
                    </div>
                    <Button type="submit" variant="contained" style={{ marginTop: 32 }}>
                        {newRole ? t("button.next") : t("manageRoleForm.edit.button.save")}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
