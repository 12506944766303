import { Field } from "formik";
import { useRoleGroups } from "../../../hooks/useRoleGroups";
import { t } from "../../../i18n/util";
import { FieldDefinition } from "../../../types";
import { CustomAutocomplete } from "../../ui/CustomAutocomplete";
import { CustomInputField } from "../../ui/CustomInputField";
import { Colors } from "../../util/Colors";
import { LinkButton } from "../../ui/LinkButton";
import { Icon } from "../../util/Icon";

export const RoleDetailsFields = ({
    onNewGroup,
    onChangeGroupName,
    isNewRole,
}: {
    onNewGroup?: () => void;
    onChangeGroupName: (value: string) => void;
    isNewRole?: boolean;
}) => {
    const { isInitialized, roleGroupOptions } = useRoleGroups();

    const fields: FieldDefinition[] = [
        {
            label: "roleDetails.name",
            name: "name",
            maxLength: 50,
        },
        {
            label: "roleDetails.description",
            name: "description",
            multiline: true,
            minRows: 5,
            maxRows: 5,
            maxLength: 100,
            type: "textarea",
        },
        {
            label: "roleDetails.roleGroup",
            name: "roleGroupId",
            component: CustomAutocomplete,
            options: roleGroupOptions,
            onChangeTextField: onChangeGroupName,
            isInitialized, // Only render once role groups are loaded to avoid value out of range error
        },
    ];

    return (
        <>
            {fields
                .filter((f) => f.isInitialized !== false)
                .map((field) => {
                    return (
                        <Field
                            key={field.name}
                            component={field.component ?? CustomInputField}
                            label={t(field.label)}
                            name={field.name}
                            type={field.type ?? "input"}
                            required={field.required}
                            options={field.options}
                            disabled={field.disabled}
                            multiline={field.multiline}
                            minRows={field.minRows}
                            maxRows={field.maxRows}
                            maxLength={field.maxLength}
                            onChangeTextField={field.onChangeTextField}
                        />
                    );
                })}
            {onNewGroup && (
                <LinkButton
                    color={Colors.PRIMARY_500}
                    onClick={onNewGroup}
                    type="button"
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                >
                    <Icon name="add" />
                    {t("roleDetails.createRoleGroup")}
                </LinkButton>
            )}
        </>
    );
};
