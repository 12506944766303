import { Field, getIn, useFormikContext } from "formik";
import _, { compact } from "lodash";
import { useCallback, useEffect } from "react";
import { useCoporatePositionSelection } from "../../../hooks/useCorporatePositionSelection";
import { useCustomer } from "../../../hooks/useCustomer";
import { useDistributionChannels } from "../../../hooks/useDistributionChannels";
import {
    DistributionChannelsGroup,
    useDistributionChannelSelection,
} from "../../../hooks/useDistributionChannelSelection";
import { useRegions } from "../../../hooks/useRegions";
import { useRegionSelection } from "../../../hooks/useRegionSelection";
import { t } from "../../../i18n/util";
import { AccountType, DistributionChannel, Region, SalesArea, UserRequest } from "../../../network/APITypes";
import { FieldDefinition, Option, Section } from "../../../types";
import { CustomInputField } from "../../ui/CustomInputField";
import { CustomPhoneInput } from "../../ui/CustomPhoneInput";
import { CustomSectionButton } from "../../ui/CustomSectionButton";
import { CustomSelect } from "../../ui/CustomSelect";
import { useUserPreferences } from "../../../hooks/useUserPreferences";
import { accountTypeOptions } from "../../util/AccountTypes";
import { InfoOutlined } from "@mui/icons-material";
import { InputAdornment, Tooltip } from "@mui/material";

type UserDetailFieldsProps = {
    user?: UserRequest;
    accountType?: AccountType;
    setAccountType?: (accountType: AccountType) => void;
};

export const UserDetailsFields = ({ user, accountType, setAccountType }: UserDetailFieldsProps) => {
    const corporatePositionName = "corporatePositionID";
    const distributionChannelName = "distributionChannelID";
    const regionName = "regionID";

    const { errors, setFieldError, setFieldValue, touched, values } = useFormikContext<any>();

    const { customer } = useCustomer(getIn(values, "customerID"));
    const { distributionChannels } = useDistributionChannels();
    const regions = useRegions();

    const getDistinctRegionsFromSalesAreas = useCallback(() => {
        return (customer?.salesAreas ?? [])
            .map(
                (area): Region => ({
                    description: area.regionDescription,
                    externalID: area.regionExternalId,
                    id: area.regionId ?? "",
                }),
            )
            .filter((region, index, self) => index === self.findIndex((r) => r.externalID === region.externalID));
    }, [customer?.salesAreas]);

    const getDistributionChannelsFromSelectedRegion = useCallback(() => {
        const groupedSalesAreasByRegionId = _.groupBy(customer?.salesAreas, "regionId");

        return (groupedSalesAreasByRegionId[getIn(values, "regionID") ?? ""] ?? []).map(
            (salesArea: SalesArea): DistributionChannel => ({
                description: salesArea.distributionChannelDescription,
                externalID: salesArea.distributionChannelExternalId,
                id: salesArea.distributionChannelId ?? "",
            }),
        );
    }, [customer?.salesAreas, values]);

    const getDistributionChannelsGroups = useCallback(() => {
        const selectedRegionDistributionChannelsGroup = {
            distributionChannels: getDistributionChannelsFromSelectedRegion(),
        };

        const konsiDistributionChannelsGroup: DistributionChannelsGroup = {
            distributionChannels:
                distributionChannels?.filter((channel) => channel.externalID.toLocaleLowerCase().startsWith("w")) ?? [],
            title: t("screen.userDetails.edit.form.distributionChannel.group.konsi.title"),
            isCollapsible: true,
        };

        return [selectedRegionDistributionChannelsGroup, konsiDistributionChannelsGroup];
    }, [distributionChannels, getDistributionChannelsFromSelectedRegion]);

    useEffect(() => {
        if (user) {
            return;
        }
        const defaultSalesArea = customer?.salesAreas?.find((salesArea) => salesArea.isDefault);
        if (!getIn(touched, "regionID")) {
            setFieldValue("regionID", defaultSalesArea?.regionId);
            setFieldValue("distributionChannelID", defaultSalesArea?.distributionChannelId);
        }
    }, [customer?.salesAreas, setFieldValue, touched, user, values.regionID]);

    useEffect(() => {
        if (!user) {
            setFieldValue("accountType", "");
        }
    }, [setFieldValue, user]);

    const distributorId = user?.distributorID ?? getIn(values, "distributorID");

    const { userPreferences } = useUserPreferences({ distributorId: distributorId });

    const corporatePositionSelection = useCoporatePositionSelection({
        distributorId,
        name: corporatePositionName,
        hideBackdrop: true,
    });

    const distributionChannelSelection = useDistributionChannelSelection({
        name: distributionChannelName,
        hideBackdrop: true,
        distributionChannelsGroups: user
            ? [{ distributionChannels: distributionChannels ?? [] }]
            : getDistributionChannelsGroups(),
    });

    const regionSelection = useRegionSelection({
        name: regionName,
        hideBackdrop: true,
        regions: user ? getDistinctRegionsFromSalesAreas() : regions ?? [],
        isUserEdit: !!user,
    });

    const titleOptions: Option[] = [
        { label: t("common.male.title"), value: "Herr" },
        { label: t("common.female.title"), value: "Frau" },
    ];

    const accountTypeValue = user ? user.accountType : accountType;
    const showUserPreferencesField = accountTypeValue === "b2b-customer" || accountTypeValue === "employee";

    const userPreferencesOptions: Option[] = [
        { label: t("screen.userDetails.edit.form.userProfile.noValue"), value: "-1" },
        ...(userPreferences?.map((userPreference) => {
            return { label: userPreference.name, value: userPreference.id ?? "" };
        }) ?? []),
    ];

    const getUserPreferencesId = () => {
        return getIn(values, "userPreferencesId");
    };

    const fields: FieldDefinition[] = compact([
        {
            label: "screen.userDetails.edit.form.title",
            name: "salutation",
            component: CustomSelect,
            options: titleOptions,
        },
        {
            label: "screen.userDetails.edit.form.firstName",
            name: "firstName",
        },
        {
            label: "screen.userDetails.edit.form.lastName",
            name: "lastName",
        },
        user && {
            label: "screen.userDetails.edit.form.accountType",
            name: "accountType",
            component: CustomSelect,
            options: accountTypeOptions,
            onChange: (event) => {
                // New account type selected -> clear role and trigger prefill with new default role in effect above
                setFieldValue("roleId", "");
                setAccountType?.(event.target.value as AccountType);
            },
        },
        showUserPreferencesField && {
            label: "screen.userDetails.edit.form.userProfile",
            name: "userPreferencesId",
            component: CustomSelect,
            options: userPreferencesOptions,
            InputProps: {
                startAdornment:
                    getUserPreferencesId() === "-1" ? (
                        <InputAdornment position="start">
                            <Tooltip title={t("screen.userDetails.edit.form.explicitProfile.tooltip")}>
                                <InfoOutlined color="primary" />
                            </Tooltip>
                        </InputAdornment>
                    ) : undefined,
            },
        },
        {
            label: "screen.userDetails.edit.form.corporatePosition",
            name: corporatePositionName,
            component: CustomSectionButton,
            options: corporatePositionSelection.options,
        },
        {
            label: "screen.userDetails.edit.form.region",
            name: "regionID",
            component: CustomSectionButton,
            options: regionSelection.options,
        },
        {
            label: "screen.userDetails.edit.form.vtChannel",
            name: distributionChannelName,
            component: CustomSectionButton,
            options: distributionChannelSelection.options,
            disabled: !getIn(values, "regionID"),
        },
        {
            label: "screen.userDetails.edit.form.email",
            name: "email",
            type: "email",
            // https://frauenthal.atlassian.net/wiki/spaces/FBPF/pages/15990810/Benutzernamen+Emailadresse+eines+Users+ndern
            // "Emailadressen von gesperrten Usern können nicht geändert werden"
            disabled: user?.status === "deactivated",
        },
        {
            label: "screen.userDetails.edit.form.phone",
            name: "phone",
            component: CustomPhoneInput,
            showHint: true,
        },
    ]);

    const handleClickSection = (section: Section | null) => {
        switch (section) {
            case corporatePositionName: {
                corporatePositionSelection.open();
                return;
            }
            case distributionChannelName: {
                distributionChannelSelection.open();
                return;
            }
            case regionName: {
                regionSelection.open();
                return;
            }
        }
    };

    return (
        <>
            {fields.map((field) => {
                return (
                    <Field
                        component={field.component ?? CustomInputField}
                        disabled={field.disabled}
                        key={field.name}
                        label={t(field.label)}
                        name={field.name}
                        onChange={field.onChange}
                        onClick={() => handleClickSection(field.name as Section)}
                        options={field.options}
                        required={field.required}
                        showHint={field.showHint}
                        type={field.type ?? "input"}
                        InputProps={field.InputProps}
                    />
                );
            })}
            {corporatePositionSelection.component}
            {distributionChannelSelection.component}
            {regionSelection.component}
        </>
    );
};
