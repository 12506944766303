import { Field, getIn, useFormikContext } from "formik";
import { AccountType, BaseCMSCustomer, PostPartnerPortalUserRequestPayload } from "../../../network/APITypes";
import { useState } from "react";
import { useCustomers } from "../../../hooks/useCustomers";
import { SearchField } from "../../ui/SearchField";
import { t } from "../../../i18n/util";
import { CustomRadioGroup } from "../../ui/CustomRadioGroup";
import { Icon } from "../../util/Icon";
import { useDeepCompareEffect } from "use-deep-compare";
import { getCustomerTypeFromAccountType } from "./CustomerNumberSelection";
import { CUSTOMER_NUMBER_SELECTION_LIMIT } from "../../../config";
import { Collapse } from "@mui/material";

export const PartnerPortalCustomerNumberSelection = ({
    onSelectCustomer,
    accountType,
}: {
    onSelectCustomer: (customer?: BaseCMSCustomer) => void;
    accountType: AccountType;
}) => {
    const { values, setFieldValue } = useFormikContext<PostPartnerPortalUserRequestPayload>();
    const [search, setSearch] = useState("");

    const { customers, isLoading } = useCustomers({
        search,
        limit: CUSTOMER_NUMBER_SELECTION_LIMIT,
        distributors: [""],
        customerTypes: [getCustomerTypeFromAccountType(accountType)],
    });
    const currentValue = getIn(values, "customerID");
    useDeepCompareEffect(() => {
        setFieldValue("customerID", "");
    }, [customers]);

    useDeepCompareEffect(() => {
        const customer = customers?.customers?.find((customer) => customer.id === currentValue);
        onSelectCustomer(customer);
    }, [currentValue, customers, onSelectCustomer]);

    const handleChangeSearch = (value: string) => {
        setSearch(value);
    };

    const handleEnter = (value: string, event?: React.KeyboardEvent<HTMLInputElement>) => {
        event?.preventDefault();
        setSearch(value);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
            <SearchField
                value={search}
                placeholder={t("addUserForm.customerNumber.search.placeholder")}
                onChange={handleChangeSearch}
                style={{ width: "100%", marginBottom: 8 }}
                onEnter={handleEnter}
            />
            <div style={{ flexGrow: 1, overflow: "auto" }}>
                <Collapse in={!isLoading}>
                    <Field
                        name="customerID"
                        component={CustomRadioGroup}
                        options={
                            customers?.customers?.map((customer) => ({
                                label: (
                                    <div
                                        className="body1"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            flexGrow: 1,
                                        }}
                                    >
                                        <span>{customer.externalId}</span>
                                        {!customer.optIn && <Icon name="editDisabled" />}
                                    </div>
                                ),
                                value: customer.id,
                            })) ?? []
                        }
                        style={{ marginTop: 8 }}
                        showValidationError={false}
                    />
                </Collapse>
            </div>
        </div>
    );
};
