import { Button, IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { PutUserRequestPayloadV2, UserPreferences, UserRequest } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import {
    hasUserManagement,
    isErrorOfType,
    isEmptyOrOnlyCountryDialCode,
    isValidPhoneNumber,
    normalizePhoneNumber,
} from "../../util/Helpers";
import { Icon } from "../../util/Icon";
import { UserDetailsFields } from "../shared/UserDetailsFields";
import { VALID_PHONE_NUMBER_LENGTH } from "../../../config";
import { AnyObject } from "yup/lib/types";

export const getAddUserPhoneAndEmailValidationSchema = () => {
    const emailOrPhoneExists = async function (
        testContext: Yup.TestContext<AnyObject>,
        type: "email" | "phone",
        value: string | undefined,
        distributorID?: string,
    ) {
        if (!value || !distributorID) {
            throw testContext.createError({ path: type, message: t("error.verify_input") });
        }

        try {
            const res = await API.postUserRequestExists({
                distributorId: distributorID === "" ? undefined : distributorID,
                email: type === "email" ? value : undefined,
                phone: type === "phone" && value !== "" ? value : undefined,
            });

            return res.exists;
        } catch (error) {
            generalStore.setError(t("error.verification"), error);
            throw testContext.createError({ path: type, message: t("error.verify_input") });
        }
    };

    const email = Yup.string()
        .email(t("validationError.email"))
        .test("email-or-phone", t("validationError.emailOrPhone"), function (value) {
            const { phone } = this.parent;

            if (!value && !isValidPhoneNumber(phone)) {
                return false;
            }

            return true;
        })
        .test("email-exists", t("validationError.email.exists"), async function (value) {
            if (!value || !Yup.string().email().isValidSync(value)) {
                return true;
            }

            const { distributorID } = this.parent;
            const emailExists = await emailOrPhoneExists(this, "email", value, distributorID);
            return !emailExists;
        });

    const phone = Yup.string()
        .test("phone-validation", t("validationError.phone"), function (value) {
            const phoneMustBeValid = !isEmptyOrOnlyCountryDialCode(value);
            if (!isValidPhoneNumber(value) && phoneMustBeValid) {
                return false;
            }
            return true;
        })
        .test("phone-exists", t("validationError.phone.exists"), async function (value) {
            if (!value || !isValidPhoneNumber(value)) {
                return true;
            }

            const { distributorID } = this.parent;
            const phoneExists = await emailOrPhoneExists(this, "phone", value, distributorID);
            return !phoneExists;
        })
        .test("email-or-phone", t("validationError.emailOrPhone"), function (value) {
            const { email } = this.parent;
            if (!Yup.string().required().email().isValidSync(email) && isEmptyOrOnlyCountryDialCode(value)) {
                return false;
            }
            return true;
        });

    return { email, phone };
};
export const getUserPhoneAndEmailValidationSchema = (user?: UserRequest | null) => {
    const userManagement = hasUserManagement(user);

    // Email is required if user has user management or if user already has an email
    const emailRequired = userManagement || !!user?.email || !!user?.appUserProfile?.email;
    // If user already has a phone number -> stays required
    const phoneRequired = (!user?.appUserProfile && !!user?.phone) || !!user?.appUserProfile?.phoneNumber;

    const emailRequiredMessage = userManagement
        ? t("validationError.userManagementNeedsEmail")
        : t("validationError.email");

    // Email Validation
    let email = Yup.string().email(t("validationError.email"));

    if (emailRequired) {
        email = email.required(emailRequiredMessage);
    } else if (!phoneRequired) {
        // xor validation for email or phone

        // Taken from https://github.com/jquense/yup/issues/79
        // and https://github.com/jquense/yup/issues/222
        email = email.test("email-or-phone", t("validationError.emailOrPhone"), function (value) {
            const { phone } = this.parent;
            if (!value && (!phone || phone.length < VALID_PHONE_NUMBER_LENGTH)) {
                return false;
            }
            return true;
        });
    }

    // Phone has to be valid if phone is required. If not required than it has to be
    // valid if it's not only a country code
    let phone = Yup.string().test("phone-validation", t("validationError.phone"), function (value) {
        const phoneMustBeValid = phoneRequired || !isEmptyOrOnlyCountryDialCode(value);
        if (!isValidPhoneNumber(value) && phoneMustBeValid) {
            return false;
        }
        return true;
    });

    // xor validation for email or phone, if none is required -> add new user case
    if (!phoneRequired && !emailRequired) {
        phone = phone.test("email-or-phone", t("validationError.emailOrPhone"), function (value) {
            const { email } = this.parent;
            if (!email && !isValidPhoneNumber(value)) {
                return false;
            }
            return true;
        });
    }
    return { email, phone };
};
export const userValidationSchema = (user?: UserRequest | null) => {
    const { email, phone } = user
        ? getUserPhoneAndEmailValidationSchema(user)
        : getAddUserPhoneAndEmailValidationSchema();

    return Yup.object().shape({
        corporatePositionID: Yup.string().required(t("validationError.corporatePosition")),
        distributionChannelID: Yup.string().required(t("validationError.vtChannel")),
        email,
        firstName: Yup.string().required(t("validationError.firstName")),
        lastName: Yup.string().required(t("validationError.lastName")),
        phone,
        regionID: Yup.string().required(t("validationError.region")),
        salutation: Yup.string().required(t("validationError.title")),
        userPreferencesId: Yup.string().optional(),
    });
};

export const EditUserForm = ({
    onClose,
    reloadUser,
    user,
    userPreference,
}: {
    onClose: () => void;
    user: UserRequest | null;
    reloadUser: () => Promise<void>;
    userPreference: UserPreferences | null;
}) => {
    const initialValues = {
        salutation: user?.salutation ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        accountType: user?.accountType ?? "",
        corporatePositionID: user?.corporatePosition?.role.id ?? "",
        distributionChannelID: user?.distributionChannel?.id ?? "",
        regionID: user?.region?.id ?? "",
        email: user?.email ?? "",
        phone: user?.phone ?? "",
        userPreferencesId: userPreference?.id ?? "",
        customerID: user?.customerID ?? "",
    };

    if (user?.appUserProfile) {
        initialValues.salutation = user.appUserProfile.salutation;
        initialValues.firstName = user.appUserProfile.firstName;
        initialValues.lastName = user.appUserProfile.lastName;
        initialValues.email = user.appUserProfile.email ?? "";
        initialValues.phone = user.appUserProfile.phoneNumber ?? "";

        if (user.appUserProfile.role) {
            initialValues.corporatePositionID = user.appUserProfile.role.id;
        }
    }

    const handleSubmit = async (model: Omit<PutUserRequestPayloadV2, "accountType"> & { accountType?: string }) => {
        try {
            generalStore.setIsLoading(true);

            const userData = cloneDeep(model);
            userData.phone = normalizePhoneNumber(userData.phone);

            if (!isValidPhoneNumber(userData.phone)) {
                // For removal -> don't send empty string
                userData.phone = undefined;
            }

            if (!userData.email) {
                // For removal -> don't send empty string
                userData.email = undefined;
            }

            if (
                !userData.userPreferencesId ||
                userData.userPreferencesId === "-1" || // For removal -> don't send empty string
                (!user?.areExplicitUserPreferences && user?.userPreferencesId === userData.userPreferencesId)
            ) {
                userData.userPreferencesId = undefined;
            }

            await API.putUserRequest(user?.id ?? "", userData as PutUserRequestPayloadV2);
            await reloadUser();
            generalStore.setSuccessMessage(t("success.editUser"));
        } catch (error) {
            if (isErrorOfType(error, "CONFLICT_USERNAME_ALREADY_EXISTS")) {
                generalStore.setError(t("error.usernameInUse"), error);
            }
        } finally {
            onClose();
            generalStore.setIsLoading(false);
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={userValidationSchema(user)}>
            <Form
                style={{
                    width: "100%",
                    height: "inherit",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
                noValidate
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h4>{t("screen.userDetails.edit.title")}</h4>
                        <IconButton onClick={onClose}>
                            <Icon name="close" />
                        </IconButton>
                    </div>
                    <div style={{ marginTop: 40 }}>
                        <UserDetailsFields user={user ?? undefined} />
                    </div>
                </div>
                <Button type="submit" variant="contained" style={{ marginTop: 32 }}>
                    {t("screen.userDetails.edit.button.text")}
                </Button>
            </Form>
        </Formik>
    );
};
