import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { AccountType, BaseCMSCustomer, PostUserRequestsPayloadV2 } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { FieldError } from "../../ui/FieldError";
import { Colors } from "../../util/Colors";
import { isValidPhoneNumber, normalizePhoneNumber } from "../../util/Helpers";
import { getUserPhoneAndEmailValidationSchema } from "../editUser/EditUserForm";
import { PartnerPortalCustomerNumberSelection } from "./AddPartnerPortalCustomerNumberSelection";
import { PartnerPortalUserDetailsFields } from "./PartnerPortalUserDetailsFields";
import { SelectRoleField } from "./SelectRoleField";
import { useRoles } from "../../../hooks/useRoles";

type AddUserFormProps = {
    accountType: AccountType;
    onClose: () => void;
    onSubmit?: () => void | Promise<void>;
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
};
type MultiFormStepConfig = {
    name: string;
    title?: string;
    step: string;
    validationSchema?: any;
    content?: React.ReactNode;
    autoOverflow?: boolean;
};

const getPartnerPortalUserValidationSchema = () => {
    const { email, phone } = getUserPhoneAndEmailValidationSchema();
    return Yup.object().shape({
        corporatePositionID: Yup.string().required(t("validationError.corporatePosition")),
        email,
        firstName: Yup.string().required(t("validationError.firstName")),
        lastName: Yup.string().required(t("validationError.lastName")),
        phone,
        salutation: Yup.string().required(t("validationError.title")),
    });
};

export const createPartnerPortalUser = async (model: PostUserRequestsPayloadV2, onUserCreated?: () => void) => {
    try {
        generalStore.setIsLoading(true);
        await API.postPartnerPortalUserRequest(model);
        generalStore.setSuccessMessage(t("success.addUser"));
    } catch (error) {
        generalStore.setError(t("error.addUser"), error);
    } finally {
        await onUserCreated?.();
        generalStore.setIsLoading(false);
        return;
    }
};

const getPages = ({
    onSelectCustomer,
    accountType,
}: {
    onSelectCustomer: (customer?: BaseCMSCustomer) => void;
    accountType: AccountType;
}): MultiFormStepConfig[] => [
    {
        autoOverflow: true,
        content: <PartnerPortalCustomerNumberSelection onSelectCustomer={onSelectCustomer} accountType={accountType} />,
        name: "customer",
        step: t("addUserForm.step.customerNumber.description"),
        validationSchema: Yup.object().shape({
            customerID: Yup.string().required(t("validationError.customerNumber")),
        }),
    },
    {
        autoOverflow: true,
        content: <PartnerPortalUserDetailsFields />,
        name: "details",
        step: t("addUserForm.step.userData.description"),
        validationSchema: getPartnerPortalUserValidationSchema,
    },
    {
        autoOverflow: true,
        content: <SelectRoleField />,
        name: "role",
        step: t("screen.userDetails.select.role.label"),
        validationSchema: Yup.object().shape({
            roleId: Yup.string().required(t("validationError.role")),
        }),
    },
];

export const AddPartnerPortalUserForm = observer(
    ({ accountType, onClose, onSubmit, step, setStep }: AddUserFormProps) => {
        const [selectedCustomer, setSelectedCustomer] = useState<BaseCMSCustomer | undefined>();
        const roles = useRoles();

        const getInitialAccountType = useCallback(() => accountType as AccountType, [accountType]);
        const getInitialRoleId = useCallback(() => {
            return roles.rolesResponse?.roles?.find((role) =>
                role.isDefaultForAccountTypeCustomerTypeCombinations.find(
                    (combination) =>
                        combination.accountType === accountType && combination.customerType === accountType,
                ),
            )?.id;
        }, [accountType, roles.rolesResponse?.roles]);
        const initialValues: PostUserRequestsPayloadV2 = useMemo(() => {
            return {
                accountType: getInitialAccountType(),
                authenticationTargetIdentifier: "PP",
                corporatePositionID: "",
                customerID: "",
                email: "",
                firstName: "",
                lastName: "",
                roleId: getInitialRoleId(),
                salutation: "",
            };
        }, [getInitialAccountType, getInitialRoleId]);

        const handleClickNext = (
            model: PostUserRequestsPayloadV2,
            helpers: FormikHelpers<PostUserRequestsPayloadV2>,
        ) => {
            helpers.setTouched({});
            helpers.setSubmitting(false);

            setStep((prevActiveStep) => prevActiveStep + 1);
        };

        const handleSubmit = async (model: PostUserRequestsPayloadV2) => {
            const userData = cloneDeep(model);
            userData.phone = normalizePhoneNumber(userData.phone);
            if (!isValidPhoneNumber(userData.phone)) {
                // For removal -> don't send empty string
                userData.phone = undefined;
            }

            if (!userData.email) {
                // For removal -> don't send empty string
                userData.email = undefined;
            }
            await createPartnerPortalUser(userData, async () => {
                await onSubmit?.();
                onClose();
            });
        };

        const handleSelectCustomer = useCallback((customer?: BaseCMSCustomer) => {
            setSelectedCustomer(customer);
        }, []);

        const getOptionalStepTitle = (index: number) => {
            if (index === 0) {
                return selectedCustomer?.externalId;
            }
        };

        const pages = getPages({
            onSelectCustomer: handleSelectCustomer,
            accountType,
        });

        const { content, validationSchema } = pages[step];

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={step < pages.length - 1 ? handleClickNext : handleSubmit}
                validationSchema={validationSchema}
                validateOnChange
                enableReinitialize
            >
                {({ errors, isSubmitting, touched }) => (
                    <Form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            overflow: "hidden",
                            height: "100%",
                        }}
                        noValidate
                    >
                        <Stepper activeStep={step} orientation="vertical">
                            {pages.map((page, index) => (
                                <Step key={page.step}>
                                    <StepLabel
                                        optional={
                                            index < step ? (
                                                <p style={{ color: Colors.GREY_500, fontSize: 14 }}>
                                                    {getOptionalStepTitle(index)}
                                                </p>
                                            ) : null
                                        }
                                    >
                                        {page.step}
                                    </StepLabel>
                                    <StepContent>{content}</StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {errors.customerID && step === 0 && touched.customerID && (
                                <FieldError>{errors.customerID}</FieldError>
                            )}
                            {step === pages.length - 1 && (
                                <Button
                                    type="submit"
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    disabled={isSubmitting}
                                    variant="contained"
                                >
                                    {t("addUserForm.button.save")}
                                </Button>
                            )}
                            {step < pages.length - 1 && (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isSubmitting || (selectedCustomer && !selectedCustomer.optIn)}
                                >
                                    {t("button.next")}
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        );
    },
);
