import {
    InputProps,
    MenuItem,
    TextField,
    TextFieldProps,
} from "@mui/material";
import { FieldInputProps, FormikState, getIn } from "formik";
import * as React from "react";
import { Option } from "../../types";
import { FieldError } from "./FieldError";

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    options?: Option[];
    hideOptional?: boolean;
    form: FormikState<any>;
    "data-id": string;
    InputProps: Partial<InputProps> | undefined;
};

export const CustomSelect = ({
    options,
    style,
    label,
    type,
    required,
    field,
    form,
    onChange,
    disabled,
    "aria-label": ariaLabel,
    "data-id": dataId,
    InputProps,
}: IProps) => {
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;
    return (
        <div style={style}>
            <TextField
                InputProps={InputProps}
                inputProps={{
                    "data-id": dataId,
                }}
                label={required ? `${label} *` : label}
                value={field.value}
                name={field.name}
                onChange={(event: React.ChangeEvent<any>) => {
                    field.onChange(event);
                    if (onChange) {
                        onChange(event);
                    }
                }}
                onBlur={field.onBlur}
                fullWidth
                type={type}
                error={showError}
                variant="outlined"
                aria-label={ariaLabel}
                select
                disabled={disabled}
            >
                {options?.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <FieldError>{showError && fieldError}</FieldError>
        </div>
    );
};
