import { useRoles } from "../../../hooks/useRoles";
import { CmsTableCell, CmsTableContainer, SettingSubSiteContainer } from "../../ui/Primitives";
import { Alert, Table, TableBody, TableRow } from "@mui/material";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { t } from "../../../i18n/util";
import { AccountTypes } from "../../util/AccountTypes";
import _, { uniqBy } from "lodash";
import { AccountType } from "../../../network/APITypes";

export const SettingsAccountTypesSite = () => {
    const { rolesResponse } = useRoles({});

    if (!rolesResponse) {
        return null;
    }

    const defaultRoles = rolesResponse.roles?.filter((role) => role.isDefaultForAccountType) ?? [];

    const flatDefaultRoles = defaultRoles.flatMap((role) =>
        role.isDefaultForAccountTypeCustomerTypeCombinations.map((combination) => ({
            ...role,
            groupByKey: combination.accountType,
        })),
    );

    const groupedDefaultRoles = _.groupBy(flatDefaultRoles, "groupByKey");

    const headerFields: TableHeaderConfig[] = [
        { column: "accountType", label: "table.label.accountType" },
        { column: "defaultRole", label: "table.label.defaultRole" },
    ];
    return (
        <SettingSubSiteContainer>
            <div
                style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", maxWidth: 920, margin: "0 auto" }}
            >
                <Alert severity="info" style={{ marginBottom: 32 }}>
                    {t("screen.settings.accountTypes.alert.text")}
                </Alert>
                <CmsTableContainer>
                    <Table style={{ tableLayout: "fixed" }}>
                        <TableHeader headerFields={headerFields} />
                        <TableBody>
                            {Object.entries(groupedDefaultRoles).map(([key, roles]) => {
                                return (
                                    <TableRow key={key}>
                                        {headerFields.map(({ column }, index) => {
                                            let label;
                                            if (column === "accountType") {
                                                label = t(AccountTypes[key as AccountType]);
                                            }
                                            if (column === "defaultRole") {
                                                const uniqueRoles = uniqBy(roles, (role) => role.name);
                                                label = uniqueRoles.reduce(
                                                    (roleNames, role, index) =>
                                                        index === 0 ? role.name : `${roleNames} | ${role.name}`,
                                                    "",
                                                );
                                            }

                                            return <CmsTableCell key={index}>{label}</CmsTableCell>;
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </CmsTableContainer>
            </div>
        </SettingSubSiteContainer>
    );
};
