import * as _ from "lodash";
import { CountryIso2 } from "react-international-phone";

// Application specific configuration variables
// potentially sourced from process.env.REACT_APP_*

// Variables defined in `public/config.js`, created from environment variables via `docker-entrypoint.sh` script
// on Docker container start will override "env" variables defined at runtime if set.
declare const ENV_API_BASE_URL: string;
declare const ENV_BASE_NAME: string;

export const API_BASE_URL = (
    _.isEmpty(ENV_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : ENV_API_BASE_URL
) as string;
export const BASE_NAME = (_.isEmpty(ENV_BASE_NAME) ? process.env.REACT_APP_BASE_NAME : ENV_BASE_NAME) as string;

// This is the actual complete public URL during runtime of the deployed frontend app.
export const PUBLIC_URL = window.location.origin + BASE_NAME;

export const PAGE_SIZE = 50;
export const MAX_PAGE_SIZE = 500;
export const SEARCH_DEBOUNCE_MS = 1000;
export const SUCCESS_AUTOHIDE_MS = 3000;
export const SIDE_PADDING = 108;

export const KB_SIZE = 1024;
export const MB_SIZE = 1024 * KB_SIZE;

export const MAX_FILE_UPLOAD_SIZE_MB = 50;
export const MAX_FILE_UPLOAD_SIZE = MAX_FILE_UPLOAD_SIZE_MB * MB_SIZE;

export const ACCEPTED_DOCUMENT_FORMATS = {
    "text/csv": [".csv"],
};

export const SEARCH_FIELD_MAX_WIDTH = 322;

export const CARD_WIDTH = 596;

export const VALID_PHONE_NUMBER_LENGTH = 7;

export const MAX_SMS_INVITE = 20;

export const MAX_OCI_CONSTANT_LENGTH = 50;

export const SUPPORTED_MOBILE_PHONE_COUNTRIES: CountryIso2[] = [
    "at",
    "de",
    "ch",
    "be",
    "bg",
    "dk",
    "ee",
    "fi",
    "fr",
    "gr",
    "ie",
    "it",
    "hr",
    "lv",
    "lt",
    "lu",
    "mt",
    "nl",
    "pl",
    "pt",
    "ro",
    "se",
    "sk",
    "si",
    "es",
    "cz",
    "hu",
    "gb",
    "cy",
];

export const ENABLE_GENERAL_SETTINGS_RETURNS = false;

export const ENABLE_LINKED_USER_INDICATION_COLUMN = false;

export const CUSTOMER_NUMBER_SELECTION_LIMIT = 5;
