import { Divider, Tabs } from "@mui/material";
import { Route, useLocation } from "react-router-dom";
import { t } from "../../../i18n/util";
import { CustomSwitch } from "../../app/router/CustomSwitch";
import { StyledLinkTab } from "../../ui/Navigation";
import { SiteContainer } from "../../ui/Primitives";
import { SettingsDomainManagementSite } from "../sites/SettingsDomainManagementSite";
import { SettingsGeneralSite } from "../sites/SettingsGeneralSite";
import { SettingsRoleGroupsSite } from "../sites/SettingsRoleGroupsSite";
import { SettingsRolesSite } from "../sites/SettingsRolesSite";
import { SettingsRoutes } from "./SettingsRoutes";
import { SettingsRoleDetailsSite } from "../sites/SettingsRoleDetailsSite";
import { SettingsRoleGroupDetailsSite } from "../sites/SettingsRoleGroupDetailsSite";
import { SettingsAccountTypesSite } from "../sites/SettingsAccountTypesSite";

const getNavigationValue = (pathname: string) => {
    // Order of tabs within tab bar
    const tabs = [
        SettingsRoutes.ROLES,
        SettingsRoutes.ROLE_GROUPS,
        SettingsRoutes.ACCOUNT_TYPES,
        SettingsRoutes.DOMAIN_MANAGEMENT,
    ];

    return tabs.findIndex((route) => pathname.startsWith(route)) + 1;
};

export const SettingsRouter = () => {
    const location = useLocation();

    return (
        <SiteContainer>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <h1>{t("screen.settings.title")}</h1>
            </div>
            <Tabs value={getNavigationValue(location.pathname)} style={{ marginTop: 8 }}>
                <StyledLinkTab
                    to={SettingsRoutes.ROOT}
                    label={t("screen.settings.navigation.tab.general")}
                    style={{ marginLeft: 0 }}
                />
                <StyledLinkTab to={SettingsRoutes.ROLES} label={t("screen.settings.navigation.tab.roles")} />
                <StyledLinkTab to={SettingsRoutes.ROLE_GROUPS} label={t("screen.settings.navigation.tab.roleGroups")} />
                <StyledLinkTab
                    to={SettingsRoutes.ACCOUNT_TYPES}
                    label={t("screen.settings.navigation.tab.accountTypes")}
                />

                <StyledLinkTab
                    to={SettingsRoutes.DOMAIN_MANAGEMENT}
                    label={t("screen.settings.navigation.tab.domainManagement")}
                />
            </Tabs>
            <Divider />
            <CustomSwitch>
                <Route exact path={[SettingsRoutes.ROOT]}>
                    <SettingsGeneralSite />
                </Route>
                <Route exact path={SettingsRoutes.ROLES}>
                    <SettingsRolesSite />
                </Route>
                <Route exact path={SettingsRoutes.ROLE_DETAILS}>
                    <SettingsRoleDetailsSite />
                </Route>
                <Route exact path={SettingsRoutes.ROLE_GROUPS}>
                    <SettingsRoleGroupsSite />
                </Route>
                <Route exact path={SettingsRoutes.ROLE_GROUP_DETAILS}>
                    <SettingsRoleGroupDetailsSite />
                </Route>
                <Route exact path={SettingsRoutes.ACCOUNT_TYPES}>
                    <SettingsAccountTypesSite />
                </Route>
                <Route exact path={SettingsRoutes.DOMAIN_MANAGEMENT}>
                    <SettingsDomainManagementSite />
                </Route>
            </CustomSwitch>
        </SiteContainer>
    );
};
