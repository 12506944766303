import * as React from "react";
import { RoleDetailsForm, RoleDetailsFormData } from "../components/forms/addRole/RoleDetailsForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { GetRoleDetailsResponse, Role, RolePayload } from "../network/APITypes";
import { Stepper } from "../types";
import * as Yup from "yup";
import { t } from "../i18n/util";

export type NewGroupParams = {
    formData?: RoleDetailsFormData;
    initialGroupName?: string;
};

export const useRoleDetailsForm = ({
    onSubmit,
    onNewGroup,
    role,
    formData, // optional initial form data
    duplicate,
    stepper,
}: {
    onSubmit: (result?: RoleDetailsFormData) => void | Promise<void>;
    onNewGroup?: (params: NewGroupParams) => void;
    role?: Role | GetRoleDetailsResponse | null;
    formData?: RoleDetailsFormData;
    duplicate?: boolean;
    stepper?: Stepper;
}) => {
    const [open, setOpen] = React.useState(false);

    const validationSchema: Yup.SchemaOf<RoleDetailsFormData> = Yup.object().shape({
        name: Yup.string().required(t("validationError.required.domain")),
        //    accountType: Yup.string().required(t("validationError.required.accountType")),
        description: Yup.string().max(100, t("validationError.domainDescriptionLength", { maxLength: 100 })),
        roleGroupId: Yup.string(),
    });

    const initialValues: RoleDetailsFormData = formData ?? {
        name: role?.name ?? "",
        description: role?.description ?? "",
        // accountType: role?.accountType ?? "",
        roleGroupId: role?.roleGroup?.id ?? "none",
    };

    const newRole = !role || duplicate;

    const handleSubmit = (formData: typeof initialValues) => {
        const sanitizedFormData = formData;
        if (sanitizedFormData.roleGroupId === "none") {
            sanitizedFormData.roleGroupId = undefined;
        }
        onSubmit(sanitizedFormData);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose}>
            <RoleDetailsForm
                onClose={handleClose}
                onSubmit={handleSubmit}
                onNewGroup={onNewGroup}
                stepper={stepper}
                validationSchema={validationSchema}
                newRole={newRole}
                initialValues={initialValues}
            />
        </CustomDrawer>
    ) : null;

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
