import { Field, getIn, useFormikContext } from "formik";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { useCustomers } from "../../../hooks/useCustomers";
import { t } from "../../../i18n/util";
import { AccountType, BaseCustomer, CustomerType, PostUserRequestsPayloadV2 } from "../../../network/APITypes";
import { CustomRadioGroup } from "../../ui/CustomRadioGroup";
import { SearchField } from "../../ui/SearchField";
import { Icon } from "../../util/Icon";
import { CUSTOMER_NUMBER_SELECTION_LIMIT } from "../../../config";
import { Collapse } from "@mui/material";

export function getCustomerTypeFromAccountType(accountType: AccountType): CustomerType {
    if (!accountType || accountType === "b2b-customer" || accountType === "employee") {
        return "customer" as CustomerType;
    }
    return accountType as CustomerType;
}
export const CustomerNumberSelection = ({
    accountType,
    initialSearch,
    onSelectCustomer,
}: {
    accountType: AccountType;
    initialSearch?: string;
    onSelectCustomer: (customer?: BaseCustomer) => void;
}) => {
    const { values, setFieldValue } = useFormikContext<PostUserRequestsPayloadV2>();
    const [search, setSearch] = React.useState(initialSearch ?? "");

    const selectedDistributorId = getIn(values, "distributorID");

    const { customers, isLoading } = useCustomers({
        customerTypes: [getCustomerTypeFromAccountType(accountType)],
        distributors: accountType === "b2b-customer" || accountType === "employee" ? [selectedDistributorId] : [""],
        limit: CUSTOMER_NUMBER_SELECTION_LIMIT,
        search: search,
    });

    const currentValue = getIn(values, "customerID");

    useDeepCompareEffect(() => {
        const currentCustomerIsSelectable = customers?.customers?.some((customer) => customer.id === currentValue);

        if (!currentCustomerIsSelectable && currentValue) {
            setFieldValue("customerID", "");
        }
    }, [customers, currentValue]);

    useDeepCompareEffect(() => {
        const customer = customers?.customers?.find((customer) => customer.id === currentValue);
        onSelectCustomer(customer);
        setFieldValue("regionID", "");
        setFieldValue("distributionChannelID", "");
    }, [currentValue, customers, onSelectCustomer]);

    const handleChangeSearch = (value: string) => {
        setSearch(value);
    };

    const handleEnter = (value: string, event?: React.KeyboardEvent<HTMLInputElement>) => {
        event?.preventDefault();
        setSearch(value);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
            <SearchField
                value={search}
                placeholder={t("addUserForm.customerNumber.search.placeholder")}
                onChange={handleChangeSearch}
                style={{ width: "100%", marginBottom: 8 }}
                onEnter={handleEnter}
            />

            <div style={{ flexGrow: 1, overflow: "auto" }}>
                <Collapse in={!isLoading}>
                    <Field
                        name="customerID"
                        component={CustomRadioGroup}
                        options={
                            customers?.customers?.map((customer) => ({
                                label: (
                                    <div
                                        className="body1"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            flexGrow: 1,
                                        }}
                                    >
                                        <span>{customer.externalId}</span>
                                        {!customer.optIn && <Icon name="editDisabled" />}
                                    </div>
                                ),
                                value: customer.id,
                            })) ?? []
                        }
                        style={{ marginTop: 8 }}
                        showValidationError={false}
                    />
                </Collapse>
            </div>
        </div>
    );
};
