import * as React from "react";
import { MessageIDS } from "./i18n/util";
import {
    AccountType,
    OrderDir,
    RoleStatusEnum,
    UserRequestSource,
    CustomerUserRequestStatusEnum,
    CustomerType,
} from "./network/APITypes";
import { InputProps, OutlinedInputProps } from "@mui/material";

type PaginationOptions = {
    offset?: number;
    limit?: number;
    orderDir?: OrderDir;
};

export type UserPaginationOptions = PaginationOptions & {
    orderBy?: string;
    ignoreLimit?: boolean;
    search?: string;
    status?: CustomerUserRequestStatusEnum[];
    isAdmin?: boolean;
    distributors?: string[];
    channels?: string[];
    source?: UserRequestSource[];
    accountTypes?: AccountType[];

    /* date format */
    activeSinceFrom?: string;
    activeSinceTo?: string;
    lastActiveFrom?: string;
    lastActiveTo?: string;
    createdAtFrom?: string;
    createdAtTo?: string;

    roles?: string[]; // multi parameter form, UUID format
};

export type DomainPaginationOptions = PaginationOptions & {
    orderBy?: string;
    type?: AccountType[];
    domain?: string;
};

export type RolesOptions = PaginationOptions & {
    orderBy?: "name" | "description" | "roleGroup" | "status" | "userCount" | "permissionCount";
    search?: string;

    // Filter
    status?: RoleStatusEnum[];
    accountTypes?: AccountType[];
    roleGroups?: string[]; // ids of role groups
};

export type RoleDetailsOptions = PaginationOptions & {
    orderBy?: "serviceGroup" | "identifier" | "description" | "name" | "roleCount" | "isGranted";
    search?: string;

    // Filter
    serviceGroups?: string[];
};

export type RoleGroupsOptions = PaginationOptions & {
    orderBy?: "name" | "description" | "count";
    search?: string;
};

export type GetCustomersOptions = { offset?: number; limit?: number; search?: string };

export type GetCustomerStatusOptions = {
    distributorId: string;
    customerId: string;
    regionId: string;
    distributionChannelId: string;
};

export type Option = {
    value: string | number;
    label: string;
};

export type FieldDefinition = {
    label: MessageIDS;
    name: string;
    type?: string;
    disabled?: boolean;
    required?: boolean;
    handleClickSection?: (section: Section | null) => void;
    component?: React.ElementType;
    options?: Option[];
    unitAdornment?: string;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    maxLength?: number;
    isInitialized?: boolean;
    onChange?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
    onChangeTextField?: (value: string) => void;
    showHint?: boolean;
    InputProps?: Partial<InputProps> | undefined;
};

export type Stepper = {
    activePage: number;
    pages: number;
    description: string;
};

export type Section = "corporatePositionID" | "distributionChannelID" | "regionID";

export interface ITab {
    values: Array<string>;
    id: string;
}

export type CustomerPaginationOptions = {
    alternativeIDS?: boolean;
    customerTypes?: CustomerType[];
    defaultOCI?: boolean;
    distributors?: string[];
    limit?: number;
    offset?: number;
    omitWithoutUsers?: boolean;
    search?: string;
};

export type UserPreferencesPaginationOptions = {
    offset?: string;
    limit?: string;
    distributorId?: string;
};

export const partnerPortalAccountTypes = ["manufacturer", "supplier", "partner"] as AccountType[];
export const customerAccountTypes = ["b2b-customer", "employee"] as AccountType[];
